var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"variant":"danger","show":_vm.userData === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(_vm._s(_vm.$t("Error Fetching Data")))]),_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.$t("No Subject Found for this ID"))+" "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'apps-users-list' }}},[_vm._v(" "+_vm._s(_vm.$t("Subject List"))+" ")])],1)]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"style-class":"vgt-table condensed","sort-options":{
        enabled: true,
        initialSortBy: { field: 'page', type: 'asc' },
      },"row-style-class":"exercise-row"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'page')?_c('div',{staticClass:"text-nowrap"},[(props.row.page)?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t("Page"))+" "),_c('b-badge',{attrs:{"variant":"info"}},[_c('span',{staticClass:"uid-text"},[_vm._v(_vm._s(props.row.page))])])],1):_vm._e()]):(props.column.field === 'right')?_c('div',{staticClass:"text-nowrap"},[_c('b-badge',{staticClass:"report-tooltip",attrs:{"variant":props.row.page < 16
                ? props.row.right[0]
                  ? 'success'
                  : props.row.time >= 30000
                  ? 'secondary'
                  : 'danger'
                : _vm.memoryRightColorCounter(props.row.right, props.row.time)}},[_c('span',{staticClass:"text-nowrap uid-text"},[_vm._v(_vm._s(props.row.page < 16 ? props.row.right[0] ? _vm.$t("Correct") : props.row.time >= 30000 ? _vm.$t("Empty") : _vm.$t("Wrong") : _vm.$t("Multiple Choice"))+" "),(props.row.page >= 16)?_c('feather-icon',{attrs:{"icon":"SearchIcon"}}):_vm._e()],1),(props.row.page > 15)?_c('span',{staticClass:"report-tooltip tooltip-text"},_vm._l((props.row.right),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(index + 1)+" - "+_vm._s(item ? _vm.$t("Correct") : props.row.time[index] >= 30000 ? _vm.$t("Empty") : _vm.$t("Wrong"))+" "),_c('br')])}),0):_vm._e()])],1):(props.column.field === 'point.type_')?_c('div',{staticClass:"text-nowrap"},[_c('b-badge',{attrs:{"variant":props.row.time >= 30000
                ? 'secondary'
                : props.row.page <= 5
                ? 'danger'
                : props.row.page <= 10
                ? 'success'
                : props.row.page <= 15
                ? 'primary'
                : 'warning'}},[_c('span',{staticClass:"text-nowrap uid-text"},[_vm._v(_vm._s(props.row.page <= 5 ? _vm.$t("Attention").toUpperCase() : props.row.page <= 10 ? _vm.$t("Logic").toUpperCase() : props.row.page <= 15 ? _vm.$t("Concentration").toUpperCase() : _vm.$t("Memory").toUpperCase()))])])],1):(props.column.field === 'time')?_c('div',{staticClass:"text-nowrap"},[_c('b-badge',{staticClass:"report-tooltip",attrs:{"size":"sm","variant":props.row.page < 16
                ? _vm.timeButtonVariant(
                    (parseInt(props.row.time) / 1000).toFixed(2),
                    props.row.right[0]
                  )
                : 'dark'}},[_c('span',{staticClass:"text-nowrap uid-text"},[_c('feather-icon',{staticClass:"feather-white",attrs:{"icon":"ClockIcon","size":"12"}}),_vm._v(" "+_vm._s((parseInt(props.row.time[props.row.time.length - 1]) / 1000).toFixed(2))+" sn ")],1),(props.row.page >= 16)?_c('span',{staticClass:"report-tooltip tooltip-text"},_vm._l((props.row.time),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(index + 1)+" - "+_vm._s((parseInt(item) / 1000).toFixed(2))+" sn "),_c('br')])}),0):_vm._e(),(props.row.page >= 16)?_c('feather-icon',{attrs:{"icon":"SearchIcon"}}):_vm._e()],1)],1):(props.column.field === 'action')?_c('span',[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-lg",modifiers:{"modal-lg":true}}],staticClass:"btn-sm",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.goToExercise(props.row)}}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('b-modal',{attrs:{"id":"modal-lg","hide-footer":"","centered":"","size":"lg","title":_vm.$t('Page Analysis')}},[_c('TestSinglePage',{key:_vm.showPageKey,attrs:{"testPage":_vm.renderPageComputed}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }