import { $themeColors } from "@themeConfig";
const characters = ["E", "D", "C", "B", "A"];
import i18n from "@/libs/i18n";
export const groupChartOptionsModify = {
	// colors: ["#313DEB"],
	tooltip: {
		x: {
			show: true,
			formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
				if (value == 1) return `${i18n.t("GBT")}`;
				return `${i18n.t("Set")} ${value - 1}`;
			},
		},
		y: {
			formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
				return `${characters[value - 1]}`;
			},
		},
	},
	yaxis: { min: 0, max: 6 },
	annotations: {
		position: "back",
		yaxis: [
			{
				y: 1,
				strokeDashArray: 0,
				label: {
					borderColor: $themeColors.danger,
					borderWidth: 2,
					style: {
						background: $themeColors.danger,
						color: "#FFFFFF",
						padding: { left: 5, right: 5, top: 1, bottom: 1 },
					},
					text: "E",
					position: "left",
					offsetX: 20,
					offsetY: 8,
				},
			},
			{
				y: 2,
				strokeDashArray: 0,
				label: {
					borderColor: $themeColors.warning,
					borderWidth: 2,
					style: {
						background: $themeColors.warning,
						color: "#FFFFFF",
						padding: { left: 5, right: 5, top: 1, bottom: 1 },
					},
					text: "D",
					position: "left",
					offsetX: 20,
					offsetY: 8,
				},
			},
			{
				y: 3,
				strokeDashArray: 0,
				label: {
					borderColor: $themeColors.primary,
					borderWidth: 2,
					style: {
						background: $themeColors.primary,
						color: "#FFFFFF",
						padding: { left: 5, right: 5, top: 1, bottom: 1 },
					},
					text: "C",
					position: "left",
					offsetX: 20,
					offsetY: 8,
				},
			},
			{
				y: 4,
				strokeDashArray: 0,
				label: {
					position: "left",
					borderColor: $themeColors.info,
					borderWidth: 2,
					style: {
						background: $themeColors.info,
						color: "#FFFFFF",
						padding: { left: 5, right: 5, top: 1, bottom: 1 },
					},
					text: "B",
					offsetX: 20,
					offsetY: 8,
				},
			},
			{
				y: 5,
				strokeDashArray: 0,
				label: {
					position: "left",
					borderColor: $themeColors.success,
					borderWidth: 2,
					style: {
						background: $themeColors.success,
						width: 18,
						color: "#FFFFFF",
						padding: { left: 5, right: 5, top: 1, bottom: 1 },
					},
					text: "A",
					offsetX: 20,
					offsetY: 8,
				},
			},
		],
	},
}; // height: 80,
