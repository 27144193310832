import { $themeColors } from "@themeConfig";
import i18n from "@/libs/i18n";
export const areaChartOptionsModify = {
	colors: [$themeColors.primary, $themeColors.info],
	theme: {
		monochrome: {
			enabled: false,
		},
	},
	tooltip: {
		x: {
			show: true,
			formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
				return `${i18n.t("Set")} ${value}`;
			},
		},
		y: {
			formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
				if (value == 8) return `Ø`;
				else return value;
			},
		},
	}, // height: 80,
	dataLabels: { enabled: false },

	xaxis: {
		min: 1,
		labels: {
			show: true,
		},
	},

	// height: 500,
	legend: {
		labels: { colors: [$themeColors.primary, $themeColors.info] },
		itemMargin: { horizontal: 5, vertical: 5 },
		position: "top",
		horizontalAlign: "right",
		show: true,
		floating: true,
		offsetY: -5,
		onItemHover: { highlightDataSeries: true },
		onItemClick: { toggleDataSeries: true },
	},
	yaxis: {
		min: 0,
		max: 10,
	},
	// yaxis: { tooltip: { y: {} } },
};
