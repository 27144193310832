import axios from "@axios";

const {
  axiosRoutes: { subject: path },
} = require("/G_CONFIG");

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // const token = localStorage.getItem("accessToken");
        // if (token !== null) {
        axios
          .get(path.getAll, { params: queryParams }) //! check impact !! please check this impact on other pages
          .then((response) => {
            console.log("inResponse");
            const responseModified = {
              ...response,
              data: {
                subjects: response.data.subjects.map((user) => {
                  // console.log(user);
                  return {
                    ...user,
                    changePercent:
                      Math.round(
                        (user.program.change / user.program.score) * 100
                      ) || 0,
                  };
                }), count : response.data.count
              },
            };
            console.log(responseModified);
            resolve(responseModified);
          })
          .catch((error) => {
            console.log("inError");
            console.log(error);
            reject(error);
          });
        // } else window.location.href = "/login";
      });
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        // const token = localStorage.getItem("accessToken");
        // if (token !== null) {
        axios
          .get(`${path.getOne}/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
        // } else window.location.href = "/login";
      });
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        // const token = localStorage.getItem("accessToken");
        // if (token !== null) {
        axios
          .post(path.new, userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
        // } else window.location.href = "/login";
      });
    },
    fetchTestComparison(ctx,{id}){
      return new Promise((resolve, reject) => {
        axios
          .put(`/test/result/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    }
  },
};
