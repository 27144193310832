import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default function useUsersList(sort) {
	// Use toast
	const toast = useToast();

	const refUserListTable = ref(null);

	// Table Handlers
	const tableColumns = [
		{
			key: "name",
			label: "Danışan",
			sortable: true,
			thAttr: {
				id: "subject-table-name",
			},
		},
		{
			key: "phone",
			label: "telefon",
			sortable: false,
			thStyle: { width: "10%" },
			tdAttr: { style: "text-align:left" },
		},
		{
			key: "attention",
			label: "Dikkat",
			sortable: false,
			thStyle: { width: "5%" },
			tdAttr: { style: "text-align:center" },
		},
		{
			key: "logic",
			label: "Mantik",
			sortable: false,
			thStyle: { width: "5%" },
			tdAttr: { style: "text-align:center" },
		},
		{
			key: "concentration",
			label: "Konsantrasyon",
			sortable: false,
			thStyle: { width: "5%" },
			tdAttr: { style: "text-align:center" },
		},
		{
			key: "memory",
			label: "Hafiza",
			sortable: false,
			thStyle: { width: "5%" },
			tdAttr: { style: "text-align:center" },
		},
		{
			key: "status",
			label: "Durum",
			sortable: true,
			thStyle: { width: "5%" },
			tdAttr: { style: "text-align:center" },
		},
		{
			key: "program",
			label: "Program",
			sortable: true,
			thStyle: { width: "5%" },
			tdAttr: { style: "text-align:center" },
		},
		{
			key: "change",
			label: "Değişim",
			sortable: false,
			thStyle: { width: "5%" },
			tdAttr: { style: "text-align:center" },
		},

		{
			key: "age",
			label: "yaş",
			sortable: true,
			thStyle: { width: "5%" },
			tdAttr: { style: "text-align:center" },
		},
		{
			key: "actions",
			sortable: false,
			label: "",
			thStyle: { width: "1%" },
			tdAttr: { style: "text-align:center" },
		},
	];
	const perPage = ref(10);
	const totalUsers = ref(0);
	const currentPage = ref(1);
	const perPageOptions = [10, 25, 50, 100];
	const searchQuery = ref("");
	const sortBy = ref("program");
	let isSortDirDesc = "";
	if (sort == "program") {
		isSortDirDesc = ref(false);
	} else {
		isSortDirDesc = ref(true);
	}
	const roleFilter = ref(null);
	const planFilter = ref(null);
	const statusFilter = ref(null);
	const programFilter = ref(null);
	const changeFilter = ref(null);

	const dataMeta = computed(() => {
		const localItemsCount = refUserListTable.value
			? refUserListTable.value.localItems.length
			: 0;
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalUsers.value,
		};
	});

	const refetchData = () => {
		refUserListTable.value.refresh();
	};

	watch(
		[
			currentPage,
			perPage,
			searchQuery,
			roleFilter,
			planFilter,
			statusFilter,
			programFilter,
			changeFilter,
		],
		() => {
			refetchData();
		},
	);

	const fetchUsers = (ctx, callback) => {
		document.getElementById("loading-bg").style.display = "block";
		store
			.dispatch("app-user/fetchUsers", {
				q: searchQuery.value,
				perPage: perPage.value,
				page: currentPage.value,
				sortBy: sortBy.value,
				sortDesc: isSortDirDesc.value,
				role: roleFilter.value,
				plan: planFilter.value,
				status: statusFilter.value,
				program: programFilter.value,
				query: sort,
			})
			.then(response => {
				const { subjects, count } = response.data;
				callback(subjects);
				totalUsers.value = count;
				document.getElementById("loading-bg").style.display = "none";
			})
			.catch(error => {
				document.getElementById("loading-bg").style.display = "none";
				toast({
					component: ToastificationContent,
					props: {
						title: "Error fetching users list",
						icon: "AlertTriangleIcon",
						variant: "danger",
					},
				});
			});
	};

	// *===============================================---*
	// *--------- UI ---------------------------------------*
	// *===============================================---*

	const resolveUserRoleVariant = role => {
		// if (role === "subscriber") return "primary";
		if (role === "E") return "danger";
		if (role === "A") return "success";
		if (role === "B") return "info";
		if (role === "RED") return "danger";
		if (role === "C") return "primary";
		if (role === "D") return "warning";
		if (role === undefined) return "secondary";
		if (role === "phone") return "success";
		return "primary";
	};
	const numberToGrade = number => {
		const values = ["-", "A", "B", "C", "D", "E"];
		return values[number];
	};

	const resolveUserRoleIcon = role => {
		// if (role === "subscriber") return "UserIcon";
		// if (role === "author") return "SettingsIcon";
		// if (role === "maintainer") return "DatabaseIcon";
		// if (role === "editor") return "Edit2Icon";
		// if (role === "admin") return "ServerIcon";
		if (role === "phone") return "PhoneIcon";

		return "UserIcon";
	};

	const resolveUserStatusVariant = status => {
		if (status === "SARI") return "warning";
		if (status === true) return "success";
		if (status === false) return "secondary";
		return "primary";
	};

	return {
		fetchUsers,
		tableColumns,
		perPage,
		currentPage,
		totalUsers,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refUserListTable,

		numberToGrade,
		resolveUserRoleVariant,
		resolveUserRoleIcon,
		resolveUserStatusVariant,
		refetchData,

		// Extra Filters
		roleFilter,
		planFilter,
		statusFilter,
	};
}
