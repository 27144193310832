<template>
  <div>
    <b-card>
      <b-row class="match-height align-items-center">
        <!-- User Info: Left col -->
        <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <div>
              <b-avatar :src="userData.avatar" :text="avatarText(userData.fullName)"
                :variant="`light-${resolveUserRoleVariant(userData.role)}`" size="104px" rounded />
            </div>
            <!-- <b-badge pill :variant="`light-${userData.active ? 'success' : 'secondary'}`" class="text-capitalize">
                  {{ userData.active ? $t("Active") : $t("Inactive") }}
                </b-badge> -->
            <!-- <div class="d-flex align-items-center">
                <b-button :to="{ name: 'clientlist-edit', params: { id: userData._id } }" variant="primary" size="sm">
                  {{ $t("Edit") }}
                </b-button>
                <b-button size="sm" :variant="userData.active ? 'outline-danger' : 'outline-success'" class="ml-1 no-wrap"
                  @click="toggleActive">
                  {{ userData.active ? $t("Delete") : $t("Activate") }}
                </b-button>
              </div> -->
            <table class="m-1 w-100">
              <tr>
                <th class="pb-50">
                  <h4>{{ userData.name }}</h4>
                </th>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="LockIcon" class="mr-75" />
                  <span class="font-weight-bold">{{ $t("Status") }}</span>
                </th>
                <td>
                  <!-- {{ userData.contact }} -->
                  <b-badge pill :variant="`light-${userData.active ? 'success' : 'secondary'}`" class="text-capitalize">
                    {{ userData.active ? $t("Active") : $t("Inactive") }}
                  </b-badge>
                </td>
              </tr>
              <tr v-if="userData.program.startDate">
                <th class="pb-50">
                  <feather-icon icon="CalendarIcon" class="mr-75" />
                  <span class="font-weight-bold">{{ $t("Program Start Date") }}</span>
                </th>
                <td>
                  {{ userData.program.startDate.slice(8, 10) }}/{{ userData.program.startDate.slice(5, 7)
                    }}/{{ userData.program.startDate.slice(0, 4) }}
                </td>
              </tr>
            </table>
          </div>
          <!-- User Stats -->
          <!-- <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">23.3k</h5>
              <small>Monthly Sales</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="TrendingUpIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">$99.87k</h5>
              <small>Annual Profit</small>
            </div>
          </div>
        </div> -->
        </b-col>
        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Age") }}</span>
              </th>
              <td class="pb-25">
                {{ userData.age }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Groups") }}</span>
              </th>
              <td class="pb-50 text-capitalize">
                <b-badge v-if="userData.program.groups.attention.group" id="attention" :variant="`light-${resolveUserRoleVariant(
                      numberToGrade(userData.program.groups.attention.group)
                    )}`">
                  <b-tooltip target="attention" custom-class="group-tooltip-wrapper" triggers="hover">
                    <b-row class="justify-content-center">
                      <b-badge variant="primary" class="d-flex align-items-end">
                        <span style="margin-right: 2px">{{ $t("Attention").toUpperCase()
                          }}</span>
                        <feather-icon v-if="
                              userData.program.groups.attention.change[
                                userData.program.groups.attention.change.length - 1
                              ] != 0 &&
                              userData.program.groups.attention.change[
                                userData.program.groups.attention.change.length - 1
                              ] > userData.program.groups.attention.group
                            " icon="ArrowUpRightIcon"></feather-icon>
                        <feather-icon v-else-if="
                              userData.program.groups.attention.change[
                                userData.program.groups.attention.change.length - 1
                              ] != 0 &&
                              userData.program.groups.attention.change[
                                userData.program.groups.attention.change.length - 1
                              ] < userData.program.groups.attention.group
                            " icon="ArrowDownRightIcon"></feather-icon>
                        <feather-icon v-else-if="
                              userData.program.groups.attention.change[
                                userData.program.groups.attention.change.length - 1
                              ] != 0 &&
                              userData.program.groups.attention.change[
                                userData.program.groups.attention.change.length - 1
                              ] == userData.program.groups.attention.group
                            " icon="CodeIcon"></feather-icon>
                      </b-badge>
                    </b-row>
                    <b-row class="justify-content-center">
                      <b-badge style="margin: 5px" v-for="(exercise, index) in userData.program.groups.attention
                            .lastUpdate" :variant="
                            exercise.indexOf('Ø') != -1
                              ? 'dark'
                              : exercise.indexOf('F') != -1
                              ? 'danger'
                              : exercise.indexOf('E') != -1
                              ? 'warning'
                              : exercise.indexOf('C') != -1 || exercise.indexOf('D') != -1
                              ? 'info'
                              : 'success'
                          " :key="'att' + index">
                        {{ exercise }}
                      </b-badge>
                    </b-row>
                  </b-tooltip>
                  {{ numberToGrade(userData.program.groups.attention.group) }}
                  <feather-icon v-if="
                        userData.program.groups.attention.change[
                          userData.program.groups.attention.change.length - 1
                        ] != 0 &&
                        userData.program.groups.attention.change[
                          userData.program.groups.attention.change.length - 1
                        ] > userData.program.groups.attention.group
                      " icon="ArrowUpRightIcon"></feather-icon>
                  <feather-icon v-else-if="
                        userData.program.groups.attention.change[
                          userData.program.groups.attention.change.length - 1
                        ] != 0 &&
                        userData.program.groups.attention.change[
                          userData.program.groups.attention.change.length - 1
                        ] < userData.program.groups.attention.group
                      " icon="ArrowDownRightIcon"></feather-icon>
                  <feather-icon v-else-if="
                        userData.program.groups.attention.change[
                          userData.program.groups.attention.change.length - 1
                        ] != 0 &&
                        userData.program.groups.attention.change[
                          userData.program.groups.attention.change.length - 1
                        ] == userData.program.groups.attention.group
                      " icon="CodeIcon"></feather-icon>
                </b-badge>
                <b-badge v-if="userData.program.groups.logic.group" class="ml-1" id="logic" :variant="`light-${resolveUserRoleVariant(
                      numberToGrade(userData.program.groups.logic.group)
                    )}`">
                  <b-tooltip target="logic" triggers="hover" custom-class="group-tooltip-wrapper">
                    <b-row class="justify-content-center">
                      <b-badge variant="success" class="d-flex align-items-end">
                        <span style="margin-right: 2px">{{ $t("Logic").toUpperCase()
                          }}</span>
                        <feather-icon v-if="
                              userData.program.groups.logic.change[
                                userData.program.groups.logic.change.length - 1
                              ] != 0 &&
                              userData.program.groups.logic.change[
                                userData.program.groups.logic.change.length - 1
                              ] > userData.program.groups.logic.group
                            " icon="ArrowUpRightIcon"></feather-icon>
                        <feather-icon v-else-if="
                              userData.program.groups.logic.change[
                                userData.program.groups.logic.change.length - 1
                              ] != 0 &&
                              userData.program.groups.logic.change[
                                userData.program.groups.logic.change.length - 1
                              ] < userData.program.groups.logic.group
                            " icon="ArrowDownRightIcon"></feather-icon>
                        <feather-icon v-else-if="
                              userData.program.groups.logic.change[
                                userData.program.groups.logic.change.length - 1
                              ] != 0 &&
                              userData.program.groups.logic.change[
                                userData.program.groups.logic.change.length - 1
                              ] == userData.program.groups.logic.group
                            " icon="CodeIcon"></feather-icon>
                      </b-badge>
                    </b-row>
                    <b-row class="justify-content-center">
                      <b-badge style="margin: 5px" v-for="(exercise, index) in userData.program.groups.logic
                            .lastUpdate" :variant="
                            exercise.indexOf('Ø') != -1
                              ? 'dark'
                              : exercise.indexOf('F') != -1
                              ? 'danger'
                              : exercise.indexOf('E') != -1
                              ? 'warning'
                              : exercise.indexOf('C') != -1 || exercise.indexOf('D') != -1
                              ? 'info'
                              : 'success'
                          " :key="'logic' + index">
                        {{ exercise }}
                      </b-badge>
                    </b-row>
                  </b-tooltip>
                  {{ numberToGrade(userData.program.groups.logic.group) }}
                  <feather-icon v-if="
                        userData.program.groups.logic.change[
                          userData.program.groups.logic.change.length - 1
                        ] != 0 &&
                        userData.program.groups.logic.change[
                          userData.program.groups.logic.change.length - 1
                        ] > userData.program.groups.logic.group
                      " icon="ArrowUpRightIcon"></feather-icon>
                  <feather-icon v-else-if="
                        userData.program.groups.logic.change[
                          userData.program.groups.logic.change.length - 1
                        ] != 0 &&
                        userData.program.groups.logic.change[
                          userData.program.groups.logic.change.length - 1
                        ] < userData.program.groups.logic.group
                      " icon="ArrowDownRightIcon"></feather-icon>
                  <feather-icon v-else-if="
                        userData.program.groups.logic.change[
                          userData.program.groups.logic.change.length - 1
                        ] != 0 &&
                        userData.program.groups.logic.change[
                          userData.program.groups.logic.change.length - 1
                        ] == userData.program.groups.logic.group
                      " icon="CodeIcon"></feather-icon>
                </b-badge>
                <b-badge v-if="userData.program.groups.concentration.group" class="ml-1" id="concentration" :variant="`light-${resolveUserRoleVariant(
                      numberToGrade(userData.program.groups.concentration.group)
                    )}`">
                  <b-tooltip target="concentration" triggers="hover" custom-class="group-tooltip-wrapper">
                    <b-row class="justify-content-center">
                      <b-badge variant="info" class="d-flex align-items-end">
                        <span style="margin-right: 2px">{{ $t("Concentration").toUpperCase()
                          }}</span>
                        <feather-icon v-if="
                              userData.program.groups.concentration.change[
                                userData.program.groups.concentration.change.length - 1
                              ] != 0 &&
                              userData.program.groups.concentration.change[
                                userData.program.groups.concentration.change.length - 1
                              ] > userData.program.groups.concentration.group
                            " icon="ArrowUpRightIcon"></feather-icon>
                        <feather-icon v-else-if="
                              userData.program.groups.concentration.change[
                                userData.program.groups.concentration.change.length - 1
                              ] != 0 &&
                              userData.program.groups.concentration.change[
                                userData.program.groups.concentration.change.length - 1
                              ] < userData.program.groups.concentration.group
                            " icon="ArrowDownRightIcon"></feather-icon>
                        <feather-icon v-else-if="
                              userData.program.groups.concentration.change[
                                userData.program.groups.concentration.change.length - 1
                              ] != 0 &&
                              userData.program.groups.concentration.change[
                                userData.program.groups.concentration.change.length - 1
                              ] == userData.program.groups.concentration.group
                            " icon="CodeIcon"></feather-icon>
                      </b-badge>
                    </b-row>
                    <b-row class="justify-content-center">
                      <b-badge style="margin: 5px" v-for="(exercise, index) in userData.program.groups.concentration
                            .lastUpdate" :variant="
                            exercise.indexOf('Ø') != -1
                              ? 'dark'
                              : exercise.indexOf('F') != -1
                              ? 'danger'
                              : exercise.indexOf('E') != -1
                              ? 'warning'
                              : exercise.indexOf('C') != -1 || exercise.indexOf('D') != -1
                              ? 'info'
                              : 'success'
                          " :key="'conc' + index">
                        {{ exercise }}
                      </b-badge>
                    </b-row>
                  </b-tooltip>
                  {{ numberToGrade(userData.program.groups.concentration.group) }}
                  <feather-icon v-if="
                        userData.program.groups.concentration.change[
                          userData.program.groups.concentration.change.length - 1
                        ] != 0 &&
                        userData.program.groups.concentration.change[
                          userData.program.groups.concentration.change.length - 1
                        ] > userData.program.groups.concentration.group
                      " icon="ArrowUpRightIcon"></feather-icon>
                  <feather-icon v-else-if="
                        userData.program.groups.concentration.change[
                          userData.program.groups.concentration.change.length - 1
                        ] != 0 &&
                        userData.program.groups.concentration.change[
                          userData.program.groups.concentration.change.length - 1
                        ] < userData.program.groups.concentration.group
                      " icon="ArrowDownRightIcon"></feather-icon>
                  <feather-icon v-else-if="
                        userData.program.groups.concentration.change[
                          userData.program.groups.concentration.change.length - 1
                        ] != 0 &&
                        userData.program.groups.concentration.change[
                          userData.program.groups.concentration.change.length - 1
                        ] == userData.program.groups.concentration.group
                      " icon="CodeIcon"></feather-icon>
                </b-badge>
                <b-badge v-if="userData.program.groups.memory.group" class="ml-1" id="memory" :variant="`light-${resolveUserRoleVariant(
                      numberToGrade(userData.program.groups.memory.group)
                    )}`">
                  <b-tooltip target="memory" triggers="hover" custom-class="group-tooltip-wrapper">
                    <b-row class="justify-content-center">
                      <b-badge variant="warning" class="d-flex align-items-end">
                        <span style="margin-right: 2px">{{ $t("Memory").toUpperCase()
                          }}</span>
                        <feather-icon v-if="
                              userData.program.groups.memory.change[
                                userData.program.groups.memory.change.length - 1
                              ] != 0 &&
                              userData.program.groups.memory.change[
                                userData.program.groups.memory.change.length - 1
                              ] > userData.program.groups.memory.group
                            " icon="ArrowUpRightIcon"></feather-icon>
                        <feather-icon v-else-if="
                              userData.program.groups.memory.change[
                                userData.program.groups.memory.change.length - 1
                              ] != 0 &&
                              userData.program.groups.memory.change[
                                userData.program.groups.memory.change.length - 1
                              ] < userData.program.groups.memory.group
                            " icon="ArrowDownRightIcon"></feather-icon>
                        <feather-icon v-else-if="
                              userData.program.groups.memory.change[
                                userData.program.groups.memory.change.length - 1
                              ] != 0 &&
                              userData.program.groups.memory.change[
                                userData.program.groups.memory.change.length - 1
                              ] == userData.program.groups.memory.group
                            " icon="CodeIcon"></feather-icon>
                      </b-badge>
                    </b-row>
                    <b-row class="justify-content-center">
                      <b-badge style="margin: 5px" v-for="(exercise, index) in userData.program.groups.memory
                            .lastUpdate" :variant="
                            exercise.indexOf('Ø') != -1
                              ? 'dark'
                              : exercise.indexOf('F') != -1
                              ? 'danger'
                              : exercise.indexOf('E') != -1
                              ? 'warning'
                              : exercise.indexOf('C') != -1 || exercise.indexOf('D') != -1
                              ? 'info'
                              : 'success'
                          " :key="'mem' + index">
                        {{ exercise }}
                      </b-badge>
                    </b-row>
                  </b-tooltip>
                  {{ numberToGrade(userData.program.groups.memory.group) }}
                  <feather-icon v-if="
                        userData.program.groups.memory.change[
                          userData.program.groups.memory.change.length - 1
                        ] != 0 &&
                        userData.program.groups.memory.change[
                          userData.program.groups.memory.change.length - 1
                        ] > userData.program.groups.memory.group
                      " icon="ArrowUpRightIcon"></feather-icon>
                  <feather-icon v-else-if="
                        userData.program.groups.memory.change[
                          userData.program.groups.memory.change.length - 1
                        ] != 0 &&
                        userData.program.groups.memory.change[
                          userData.program.groups.memory.change.length - 1
                        ] < userData.program.groups.memory.group
                      " icon="ArrowDownRightIcon"></feather-icon>
                  <feather-icon v-else-if="
                        userData.program.groups.memory.change[
                          userData.program.groups.memory.change.length - 1
                        ] != 0 &&
                        userData.program.groups.memory.change[
                          userData.program.groups.memory.change.length - 1
                        ] == userData.program.groups.memory.group
                      " icon="CodeIcon"></feather-icon>
                </b-badge>
              </td>
              <!-- make a button on the far right of this table that shows #exercise-cheatsheet modal -->
              <td class="text-right" v-if="userData.program.active">
                <b-button variant="outline-primary" size="sm" v-b-modal.exercise-cheatsheet
                  style="padding: 3px 7px 3px 7px"> ? </b-button>
              </td>
            </tr>
            <!-- unnecessary -->
            <!-- <tr>
              <th class="pb-50">
                <feather-icon icon="FlagIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Gender") }}</span>
              </th>
              <td class="pb-50">
                {{ userData.gender }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Contact") }}</span>
              </th>
              <td colspan="5">
                <a v-if="userData.guardian[0].phone"
                  :href="`tel:${userData.guardian[0].phone}`">{{ userData.guardian[0].phone }}</a>
                <span v-else>
                  {{ $t("No phone number") }}
                </span>
              </td>
            </tr> -->
            <tr>
              <th class="pb-75">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Registration Date") }}</span>
              </th>
              <td>
                {{ userData.createdAt.slice(8, 10) }}/{{ userData.createdAt.slice(5, 7)
                  }}/{{ userData.createdAt.slice(0, 4) }}
              </td>
            </tr>
            <tr v-if="userData.program.stopDate">
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Program Stop Date") }}</span>
              </th>
              <td class="pb-25">
                {{ userData.program.stopDate.slice(8, 10) }}/{{ userData.program.stopDate.slice(5, 7)
                  }}/{{ userData.program.stopDate.slice(0, 4) }}
              </td>
            </tr>
            <tr v-else>
              <th></th>
              <td></td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12" xl="12" class="d-flex align-items-center">
          <span class="font-weight-bold mr-1">Danışan Program Bilgileri</span>
        </b-col>
      </b-row>
    </b-card>
    <b-modal id="exercise-cheatsheet" :title="$t('Group Change Table')" size="lg" centered>
      <b-row>
        <b-col cols="8">
          <table>
            <tr class="cheatsheet-tr" v-for="(row, index) in tableMatrix" :key="'row-' + index" :class="{
                  green: index >= 2 && index < 4,
                  blue: index >= 4 && index < 6,
                  yellow: index == 6,
                  red: index == 7,
                }">
              <td class="cheatsheet-td" v-for="(col, index) in row" :key="'col' + index" :class="{
                    green: index >= 2 && index < 4,
                    blue: (index >= 4 && index < 6) || col == 'Ø-Ø',
                    yellow: index == 6,
                    red: index == 7,
                  }">
                <span>{{ col }}</span>
              </td>
            </tr>
          </table>
        </b-col>
        <b-col>
          <b-row class="align-items-center justify-content-center" style="border-bottom: 1px solid #de39a5; margin: 5px">
            <b-badge variant="success" pill style="margin: 5px"> 3 </b-badge>
            <b-badge variant="success" pill style="margin: 5px">
              <feather-icon icon="ArrowUpIcon"></feather-icon>
            </b-badge>
          </b-row>
          <b-row class="align-items-center justify-content-center" style="border-bottom: 1px solid #de39a5; margin: 5px">
            <b-badge variant="warning" pill style="margin: 5px"> 3 </b-badge>
            <b-badge variant="danger" pill style="margin: 5px">
              <feather-icon icon="ArrowDownIcon"></feather-icon>
            </b-badge>
          </b-row>
          <b-row class="align-items-center justify-content-center" style="border-bottom: 1px solid #de39a5; margin: 5px">
            <b-badge variant="danger" pill style="margin: 5px"> 3 </b-badge>
            <b-badge variant="danger" pill style="margin: 5px">
              <feather-icon icon="ArrowDownIcon"></feather-icon>
            </b-badge>
          </b-row>
          <b-row class="align-items-center justify-content-center" style="border-bottom: 1px solid #de39a5; margin: 5px">
            <b-badge variant="danger" pill style="margin: 5px"> 2 </b-badge>
            <b-badge variant="secondary" pill style="margin: 5px">+</b-badge>
            <b-badge variant="warning" pill style="margin: 5px"> 1 </b-badge>
            <b-badge variant="danger" pill style="margin: 5px">
              <feather-icon icon="ArrowDownIcon"></feather-icon>
            </b-badge>
          </b-row>
          <b-row class="align-items-center justify-content-center" style="border-bottom: 1px solid #de39a5; margin: 5px">
            <b-badge variant="danger" pill style="margin: 5px"> 2 </b-badge>
            <b-badge variant="secondary" pill style="margin: 5px">+</b-badge>
            <b-badge variant="info" pill style="margin: 5px"> 1 </b-badge>
            <b-badge variant="danger" pill style="margin: 5px">
              <feather-icon icon="ArrowDownIcon"></feather-icon>
            </b-badge>
          </b-row>
          <b-row class="align-items-center justify-content-center">
            <span>{{ $t("Other") }}</span>
            <b-badge pill variant="info" style="margin: 5px">
              <feather-icon icon="CodeIcon"></feather-icon>
            </b-badge>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BLink,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useUsersList from "../users-list/useUsersList";
import FeatherIcon from "../../../../@core/components/feather-icon/FeatherIcon.vue";
import axios from "@axios";
const {
  axiosRoutes: { subject: path },
} = require("/G_CONFIG");
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    FeatherIcon,
    BBadge,
    BLink,
    FeatherIcon,
    BTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toggleActive() {
      this.$route.params.id;
      axios
        .patch(path.edit + "/" + this.$route.params.id, {
          active: !this.userData.active,
        })
        .then(() => {
          this.userData.active = !this.userData.active;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    blurNotesField() {
      this.editNotes = false;
      console.log("notes", this.userData.note);
      axios
        .patch(path.edit + "/" + this.$route.params.id, {
          note: this.userData.note,
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // focusNotesField() {
    //   this.editNotes = true;
    // },
    numberToGrade(number) {
      const values = ["A", "B", "C", "D", "E"];
      return values[number - 1];
    },
    gradeColor(number) {
      const values = ["success", "info", "primary", "warning", "danger"];
      return values[number - 1];
    },
  },
  data() {
    return {
      editNotes: false,
      tableMatrix: [
        ["Ø-Ø", "Hata", " A ", " B ", " C ", " D ", " E ", " F "],
        ["Süre", "   ", "   ", "   ", "   ", "   ", "   ", "   "],
        [" A ", "    ", "A-A", "A-B", "A-C", "A-D", "A-E", "A-F"],
        [" B ", "    ", "B-A", "B-B", "B-C", "B-D", "B-E", "B-F"],
        [" C ", "    ", "C-A", "C-B", "C-C", "C-D", "C-E", "C-F"],
        [" D ", "    ", "D-A", "D-B", "D-C", "D-D", "D-E", "D-F"],
        [" E ", "    ", "E-A", "E-B", "E-C", "E-D", "E-E", "E-F"],
        [" F ", "    ", "F-A", "F-B", "F-C", "F-D", "F-E", "F-F"],
      ],
    };
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList();
    return {
      avatarText,
      resolveUserRoleVariant,
    };
  },
};
</script>

<style scoped>
.pointer-on-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.cheatsheet-tr.green {
  background-color: #28c76f;
  color: white;
}
.cheatsheet-tr.blue {
  background-color: #00cfe8;
  color: white;
}
.cheatsheet-tr.yellow {
  background-color: #ff9f43;
  color: white;
}
.cheatsheet-tr.red {
  background-color: #ea5455;
  color: white;
}
.cheatsheet-tr:not(.green):not(.blue):not(.yellow):not(.red) > .cheatsheet-td.green {
  color: white;

  background-color: #28c76f;
}
.cheatsheet-tr:not(.green):not(.blue):not(.yellow):not(.red) > .cheatsheet-td.blue {
  color: white;
  background-color: #00cfe8;
}
.cheatsheet-tr:not(.green):not(.blue):not(.yellow):not(.red) > .cheatsheet-td.yellow {
  color: white;
  background-color: #ff9f43;
}

.cheatsheet-tr:not(.green):not(.blue):not(.yellow):not(.red) > .cheatsheet-td.red {
  color: white;
  background-color: #ea5455;
}
.cheatsheet-tr.green > .cheatsheet-td.green {
  background-color: #28c76f !important;
}
.cheatsheet-tr.green > .cheatsheet-td.blue {
  background-color: #00cfe8 !important;
}
.cheatsheet-tr.green > .cheatsheet-td.yellow {
  background-color: #ff9f43 !important;
}
.cheatsheet-tr.green > .cheatsheet-td.red {
  background-color: #ea5455 !important;
}

.cheatsheet-tr.blue > .cheatsheet-td.blue {
  background-color: #00cfe8 !important;
}
.cheatsheet-tr.blue > .cheatsheet-td.yellow {
  background-color: #ff9f43 !important;
}
.cheatsheet-tr.blue > .cheatsheet-td.red {
  background-color: #ea5455 !important;
}
.cheatsheet-tr.yellow > .cheatsheet-td.yellow {
  background-color: #ff9f43 !important;
}
.cheatsheet-tr.yellow > .cheatsheet-td.red {
  background-color: #ea5455 !important;
}
.cheatsheet-tr.red > .cheatsheet-td.red {
  background-color: #ea5455 !important;
}

.cheatsheet-td:not(.no-bg) {
  padding: 5px;
  /* margin:5px; */
  width: 10%;
  text-align: center;
}

.tooltip-inner {
  background-color: green !important;
}
</style>
<style lang="scss">
.group-tooltip-wrapper > .arrow {
  background: transparent /*!important*/;
  border: transparent /*!important*/;
}
.group-tooltip-wrapper > .tooltip-inner {
  background: rgba(48, 48, 48, 0.2) /*!important*/;
  width: 150px;
}
</style>
