<template>
  <b-card no-body class="border-primary">
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        {{ $t("Last Test Information") }}
      </h5>
      <!-- @click="handleShowTest" -->
      <b-button v-if="testData != null" variant="primary" class="btn-sm"
        @click="$emit('test-report', testData._id)"><feather-icon icon="SearchIcon"></feather-icon>
      </b-button>
      <small class="text-muted w-100">{{ testData
            ? new Date(testData.createdAt).toLocaleDateString($i18n.locale, {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : $t("Not Done")
        }}</small>
    </b-card-header>
    <b-card-body>
      <ul class="list-unstyled my-1">
        <li style="">
          <span class="align-middle">{{ $t("Attention:") }} </span>
          <b-badge v-if="testData" :variant="testData ? gradeColor(testData.attention) : 'dark'">
            {{ testData ? numberToGrade(testData.attention) : "" }}
          </b-badge>
          <span v-else> - </span>
          <span> </span>
        </li>
        <li>
          <span class="align-middle">{{ $t("Logic:") }} </span>
          <b-badge v-if="testData" :variant="testData ? gradeColor(testData.logic) : 'dark'">
            {{ testData ? numberToGrade(testData.logic) : "" }}
          </b-badge>
          <span v-else> - </span>
          <span> </span>
        </li>
        <li>
          <span class="align-middle">{{ $t("Concentration:") }} </span>
          <b-badge v-if="testData" :variant="testData ? gradeColor(testData.concentration) : 'dark'">
            {{ testData ? numberToGrade(testData.concentration) : "" }}
          </b-badge>
          <span v-else> - </span>
          <span> </span>
        </li>
        <li>
          <span class="align-middle">{{ $t("Memory:") }} </span>
          <b-badge v-if="testData" :variant="testData ? gradeColor(testData.memory) : 'dark'">
            {{ testData ? numberToGrade(testData.memory) : "" }}
          </b-badge>
          <span v-else> - </span>
          <span> </span>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BLink,
  BRow,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BLink,
  },
  props: {
    testData: {
      type: Object,
    },
    programData: {
      type: Object,
      required: true,
    },
    grade: String,
    isFirm: { type: Boolean, default: false },
    isActive: { type: Boolean, default: false },
    // subjectID: { type: String, default: "" },
  },
  computed: {
    userID() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      userRefunds: 0,
    };
  },
  created() {
    // axios
    //   .get("/user/refunds")
    //   .then(({ data }) => {
    //     this.userRefunds = data.refunds;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  methods: {
    handleShowTest() {
      // this.$router.push({ name: "test-detail", params: { id: this.userID } });
      console.log(this.testData);
    },
    numberToGrade(number) {
      if (number > 0) number--;
      const values = ["E", "D", "C", "B", "A"];
      return values[number];
    },
    gradeColor(number) {
      if (number > 0) number--;
      const values = ["danger", "warning", "primary", "info", "success"];
      return values[number];
    },
    confirmBeforeTest() {
      console.log("confirmBeforeTest");
      this.$swal(
        {
          title: this.$t("Program will be applied!"),
          text:
            this.$t("When you accept you will be taken to the test page.") +
            " " +
            this.$t("Program will be applied after test is completed."),
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#34bde6",
          cancelButtonText: this.$t("Cancel"),
          confirmButtonColor: "#de39a5",
          confirmButtonText: this.$t("I will start the test"),
        },
        {}
      ).then(({ isConfirmed }) => {
        // if (result.value) {
        //   this.$swal({
        //     title: this.$t("Applied!"),
        //     text: this.$t("Your GBT has been applied."),
        //     icon: "success",
        //     confirmButtonColor: "#7367F0",
        //   });
        // }
        if (isConfirmed) this.$router.push({ name: "test-testing", params: {} });
        // console.log("CONFIRMED");
        // else console.log("NOT CONFIRMED");
      });
      // :to="
      //       $can('create', `${isFirm ? 'F' : 'S'}.Test`)
      //         ? { name: 'test-testing', params: {} }
      //         : {}
      //     "
    },
    refundProgram() {
      this.$swal(
        {
          title: this.$t("Program will be refunded!"),
          text: this.$t("When you accept you will be taken to the refund page."),
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#34bde6",
          cancelButtonText: "Vazgeç",
          confirmButtonColor: "#de39a5",
          confirmButtonText: this.$t("I will refund"),
        },
        {}
      ).then(({ isConfirmed }) => {
        console.log("CONFIRMED");
        if (isConfirmed) {
          axios({
            method: "get",
            url: "/subject/refund/" + this.userID,
          }).then((response) => {
            console.log(response);
            if (response.data.status) {
              this.$swal({
                title: this.$t("Refunded!"),
                text: this.$t("Your program has been refunded."),
                icon: "success",
                confirmButtonColor: "#7367F0",
              }).then(() => {
                window.location.href = "/clientlist/" + this.userID;
              });
            } else {
              this.$swal({
                title: this.$t("Error!"),
                text: this.$t("Your program has not been refunded."),
                icon: "error",
                confirmButtonColor: "#7367F0",
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.list-unstyled > li > span:first-child {
  width: 50%;
}

.list-unstyled > li {
  display: flex;
  justify-content: space-between;
  margin: 2px 0 2px 0;
}
</style>
