<template>
  <b-card no-body class="border-primary">
    <b-card-header
      class="d-flex justify-content-between align-items-center mb-0 cardheadercustom"
    >
      <h5 class="mb-0">
        {{ $t("Last Test Information") }}
      </h5>
      <!-- @click="handleShowTest" -->
      <div class="d-flex justify-content-between align-items-end">
        <b-button
          v-if="testData != null"
          variant="primary"
          class="btn-sm"
          :to="{
            name: 'testcompare',
            params: { id: $route.params.id, testid: testData._id },
          }"
          ><feather-icon icon="PrinterIcon"></feather-icon>
        </b-button>
        <b-button
          v-if="testData != null"
          variant="primary"
          class="btn-sm ml-1"
          :to="{
            name: 'testreport',
            params: { id: $route.params.id, testid: testData._id },
          }"
          target="_blank"
          ><feather-icon icon="SearchIcon"></feather-icon>
        </b-button>
      </div>

      <small class="text-muted w-100">{{
        testData
          ? new Date(testData.createdAt).toLocaleDateString($i18n.locale, {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : $t("Not Done")
      }}</small>
    </b-card-header>
    <b-card-body>
      <ul class="list-unstyled">
        <li style="">
          <span class="align-middle">{{ $t("Attention:") }} </span>
          <b-badge
            v-if="testData"
            :variant="testData ? gradeColor(testData.attention) : 'dark'"
          >
            {{ testData ? numberToGrade(testData.attention) : "" }}
          </b-badge>
          <span v-else> - </span>
          <span> </span>
        </li>
        <li>
          <span class="align-middle">{{ $t("Logic:") }} </span>
          <b-badge
            v-if="testData"
            :variant="testData ? gradeColor(testData.logic) : 'dark'"
          >
            {{ testData ? numberToGrade(testData.logic) : "" }}
          </b-badge>
          <span v-else> - </span>
          <span> </span>
        </li>
        <li>
          <span class="align-middle">{{ $t("Concentration:") }} </span>
          <b-badge
            v-if="testData"
            :variant="testData ? gradeColor(testData.concentration) : 'dark'"
          >
            {{ testData ? numberToGrade(testData.concentration) : "" }}
          </b-badge>
          <span v-else> - </span>
          <span> </span>
        </li>
        <li>
          <span class="align-middle">{{ $t("Memory:") }} </span>
          <b-badge
            v-if="testData"
            :variant="testData ? gradeColor(testData.memory) : 'dark'"
          >
            {{ testData ? numberToGrade(testData.memory) : "" }}
          </b-badge>
          <span v-else> - </span>
          <span> </span>
        </li>
      </ul>
      <div v-if="programData.active">
        <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :variant="
              $can('create', `${isFirm ? 'F' : 'S'}.Test`) &&
              programData.active &&
              programData.exercisesDone > programData.exercisesTotal / 2
                ? 'primary'
                : 'secondary'
            " :disabled="
              $can('create', `${isFirm ? 'F' : 'S'}.Test`) &&
              programData.active &&
              programData.exercisesDone > programData.exercisesTotal / 2
                ? false
                : true
            " block :to="{ name: 'test-testing', params: {} }">
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon size="1.5x" icon="ActivityIcon" class="mr-1"></feather-icon><span>{{ $t("Apply Test") }}</span>
          </div>
        </b-button> -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :variant="
            $can('read', `${isFirm ? 'F' : 'S'}.Exercise`) &&
            programData.groups.attention.group &&
            programData.groups.logic.group &&
            programData.groups.concentration.group &&
            programData.groups.memory.group &&
            programData.active
              ? 'primary'
              : 'secondary'
          "
          block
          class="buttoncustom"
          :to="{ name: 'exercises-detail', params: {} }"
          :disabled="
            $can('read', `${isFirm ? 'F' : 'S'}.Exercise`) &&
            programData.groups.attention.group &&
            programData.groups.logic.group &&
            programData.groups.concentration.group &&
            programData.groups.memory.group &&
            programData.active &&
            isActive
              ? false
              : true
          "
        >
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon size="1.5x" icon="AlignJustifyIcon" class="mr-1"></feather-icon
            ><span>{{ $t("Set Operations") }}</span>
          </div>
        </b-button>
        <b-button
          :disabled="!programData.refundable || userRefunds <= 0"
          block
          class="buttoncustom"
          :variant="programData.refundable && userRefunds > 0 ? 'info' : 'dark'"
          @click="programData.refundable && userRefunds > 0 ? refundProgram() : ''"
        >
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon size="1.5x" icon="RotateCcwIcon" class="mr-1" />
            <span>{{ $t("Cancel Program") }}</span>
          </div>
        </b-button>
      </div>
      <div v-else>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :variant="
            $can('create', `${isFirm ? 'F' : 'S'}.Test`) ? 'primary' : 'secondary'
          "
          :disabled="
            (!isActive || userPrograms <= 0) &&
            $can('create', `${isFirm ? 'F' : 'S'}.Test`)
          "
          block
          class="buttoncustom"
          @click="$can('create', `${isFirm ? 'F' : 'S'}.Test`) ? confirmBeforeTest() : ''"
        >
          <!-- :disabled="
                    $can('create',`${isFirm?'F':'S'}.Test`) ? 
                    programData.active
                      ? programData.exercisesDone > programData.exercisesTotal / 2
                        ? false
                        : true
                      : false
                      : true
                  " -->
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon size="1.5x" icon="ActivityIcon" class="mr-1"></feather-icon
            ><span>{{ $t("Apply GBT") }}</span>
          </div>
        </b-button>
        <b-button
          v-if="$can('create', `${isFirm ? 'F' : 'S'}.Test`) ? false : true"
          block
          variant="primary"
          :to="{
            name: 'account-settings',
          }"
          :disabled="!isActive"
          class="buttoncustom"
        >
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon size="1.5x" icon="AwardIcon" class="mr-1"></feather-icon>
            <span>{{ $t("Approve Your Account") }}</span>
          </div>
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BLink,
  BRow,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BLink,
  },
  props: {
    testData: {
      type: Object,
    },
    programData: {
      type: Object,
      required: true,
    },
    grade: String,
    isFirm: { type: Boolean, default: false },
    isActive: { type: Boolean, default: false },
    // subjectID: { type: String, default: "" },
  },
  computed: {
    userID() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      userRefunds: 0,
      userPrograms: 0,
    };
  },
  created() {
    axios
      .get("/user/refunds")
      .then(({ data }) => {
        this.userRefunds = data.refunds;
        this.userPrograms = data.programs;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    handleShowTest() {
      // this.$router.push({ name: "test-detail", params: { id: this.userID } });
      console.log(this.testData);
    },
    numberToGrade(number) {
      if (number > 0) number--;
      const values = ["E", "D", "C", "B", "A"];
      return values[number];
    },
    gradeColor(number) {
      if (number > 0) number--;
      const values = ["danger", "warning", "primary", "info", "success"];
      return values[number];
    },
    confirmBeforeTest() {
      this.$swal(
        {
          title: this.$t("Program will be applied!"),
          text: [
            this.$t("When you accept you will be taken to the test page.") +
              this.$t("Program will be applied after test is completed."),
          ],
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#34bde6",
          cancelButtonText: this.$t("Cancel"),
          confirmButtonColor: "#de39a5",
          confirmButtonText: this.$t("I will start the test"),
        },
        {}
      ).then(({ isConfirmed }) => {
        // if (result.value) {
        //   this.$swal({
        //     title: this.$t("Applied!"),
        //     text: this.$t("Your GBT has been applied."),
        //     icon: "success",
        //     confirmButtonColor: "#7367F0",
        //   });
        // }
        if (isConfirmed) this.$router.push({ name: "test-testing", params: {} });
        // console.log("CONFIRMED");
        // else console.log("NOT CONFIRMED");
      });
      // :to="
      //       $can('create', `${isFirm ? 'F' : 'S'}.Test`)
      //         ? { name: 'test-testing', params: {} }
      //         : {}
      //     "
    },
    refundProgram() {
      this.$swal(
        {
          title: this.$t("Program will be Cancelled!"),
          text:
            this.$t(
              "When you accept, Subject's program will be cancelled and you will get a program right back."
            ) +
            "\n\r" +
            this.$t(
              "In order to apply the program again, you will have to test this subject again and use a program right."
            ),
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#34bde6",
          cancelButtonText: "Vazgeç",
          confirmButtonColor: "#de39a5",
          confirmButtonText: this.$t("I will Cancel"),
        },
        {}
      ).then(({ isConfirmed }) => {
        console.log("CONFIRMED");
        if (isConfirmed) {
          axios({
            method: "get",
            url: "/subject/refund/" + this.userID,
          }).then((response) => {
            console.log(response);
            if (response.data.status) {
              this.$swal({
                title: this.$t("Refunded") + "!",
                text: this.$t("Your program has been refunded."),
                icon: "success",
                confirmButtonColor: "#7367F0",
              }).then(() => {
                window.location.href = "/clientlist/" + this.userID;
              });
            } else {
              this.$swal({
                title: this.$t("Error!"),
                text: this.$t("Your program has not been refunded."),
                icon: "error",
                confirmButtonColor: "#7367F0",
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.list-unstyled {
  margin: 0 0 5px 0;
}
.cardheadercustom {
  padding-top: 5px;
  padding-bottom: 0;
}
.buttoncustom {
  padding: 5px;
}
.cardheadercustom > h5 {
  margin: 0;
  padding: 0;
}
.list-unstyled > li > span:first-child {
  width: 50%;
}
.list-unstyled > li {
  display: flex;
  justify-content: space-between;
  margin: 2px 0 2px 0;
}
</style>
