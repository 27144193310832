<template>
  <div>
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">{{ $t("Error Fetching Data") }}</h4>
      <div class="alert-body">
        {{ $t("No Subject Found for this ID") }}
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          {{ $t("Subject List") }}
        </b-link>
      </div>
    </b-alert>
    <!-- First Row -->
    <!-- <b-row class="match-height" v-if="userData">
      <b-col cols="12" xl="9" lg="8" md="7">
        <user-view-user-info-card :user-data="userData" />
      </b-col>
      <b-col cols="12" md="5" xl="3" lg="4">
        <test-card :test-data="testData" :program-data="userData.program" :grade="userData.subjectClass"
          :isFirm="userData.isFirmSubject" :isActive="userData.active" :subjectID="userData._id" />
      </b-col>
    </b-row> -->
    <!-- Second Row -->
    <!-- Alert: No item found -->
    <vue-good-table :columns="columns" :rows="rows" :rtl="direction" style-class="vgt-table condensed" :sort-options="{
          enabled: true,
          initialSortBy: { field: 'page', type: 'asc' },
        }" row-style-class="exercise-row">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <div v-if="props.column.field === 'page'" class="text-nowrap">
          <span v-if="props.row.page" class="text-nowrap">{{ $t("Page") }}
            <b-badge variant="info">
              <span class="uid-text">{{ props.row.page }}</span>
            </b-badge></span>
        </div>
        <!-- Column: Status -->
        <div v-else-if="props.column.field === 'right'" class="text-nowrap">
          <b-badge :variant="
                props.row.page < 16
                  ? props.row.right[0]
                    ? 'success'
                    : props.row.time >= 30000
                    ? 'secondary'
                    : 'danger'
                  : memoryRightColorCounter(props.row.right, props.row.time)
              " class="report-tooltip">
            <span class="text-nowrap uid-text">{{ props.row.page < 16
                    ? props.row.right[0]
                      ? $t("Correct")
                      : props.row.time >= 30000
                      ? $t("Empty")
                      : $t("Wrong")
                    : $t("Multiple Choice")
                }}
              <feather-icon v-if="props.row.page >= 16" icon="SearchIcon"></feather-icon>
            </span>
            <span class="report-tooltip tooltip-text" v-if="props.row.page > 15">
              <span v-for="(item, index) in props.row.right" :key="index">
                {{ index + 1 }} - {{ item
                      ? $t("Correct")
                      : props.row.time[index] >= 30000
                      ? $t("Empty")
                      : $t("Wrong")
                  }}
                <br />
              </span>
              <!-- {{ memoryAnswerSheet(props.row.right, props.row.time) }} -->
            </span>
          </b-badge>
        </div>
        <div v-else-if="props.column.field === 'point.type_'" class="text-nowrap">
          <b-badge :variant="
                props.row.time >= 30000
                  ? 'secondary'
                  : props.row.page <= 5
                  ? 'danger'
                  : props.row.page <= 10
                  ? 'success'
                  : props.row.page <= 15
                  ? 'primary'
                  : 'warning'
              ">
            <span class="text-nowrap uid-text">{{ props.row.page <= 5
                  ? $t("Attention").toUpperCase()
                  : props.row.page <= 10
                  ? $t("Logic").toUpperCase()
                  : props.row.page <= 15
                  ? $t("Concentration").toUpperCase()
                  : $t("Memory").toUpperCase()
              }}</span>
          </b-badge>
        </div>
        <div v-else-if="props.column.field === 'time'" class="text-nowrap">
          <b-badge size="sm" :variant="
                props.row.page < 16
                  ? timeButtonVariant(
                      (parseInt(props.row.time) / 1000).toFixed(2),
                      props.row.right[0]
                    )
                  : 'dark'
              " class="report-tooltip">
            <span class="text-nowrap uid-text">
              <feather-icon icon="ClockIcon" size="12" class="feather-white" />
              {{ (parseInt(props.row.time[props.row.time.length - 1]) / 1000).toFixed(2)
                }} sn </span>
            <span v-if="props.row.page >= 16" class="report-tooltip tooltip-text">
              <span v-for="(item, index) in props.row.time" :key="index">
                {{ index + 1 }} - {{ (parseInt(item) / 1000).toFixed(2) }} sn <br />
              </span>
              <!-- {{ memoryTimeParser(props.row.time) }} -->
            </span>
            <feather-icon v-if="props.row.page >= 16" icon="SearchIcon"></feather-icon>
          </b-badge>
        </div>
        <!-- <div v-else-if="props.column.field === 'point.normalized'" class="text-nowrap">
          <b-badge
            size="sm"
            :variant="
              props.row.time >= 30000
                ? 'secondary'
                : pointButtonVariant(
                    props.row.point.normalized
                      ? parseFloat(props.row.point.normalized).toFixed(2)
                      : 0
                  )
            "
          >
            <span class="text-nowrap uid-text">
              <feather-icon icon="StarIcon" size="12" class="feather-white" />
              {{
                props.row.point.normalized
                  ? parseFloat(props.row.point.normalized).toFixed(2)
                  : 0
              }}
            </span>
          </b-badge>
        </div> -->
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-button variant="primary" class="btn-sm" @click="goToExercise(props.row)" v-b-modal.modal-lg>
            <feather-icon icon="SearchIcon"></feather-icon>
          </b-button>
        </span>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
    </vue-good-table>
    <b-modal id="modal-lg" hide-footer centered size="lg" :title="$t('Page Analysis')">
      <TestSinglePage :testPage="renderPageComputed" :key="showPageKey"></TestSinglePage>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BAlert,
  BTable,
  BButton,
  BCard,
  BRow,
  BCol,
  BBadge,
  BLink,
  BModal,
  VBModal,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import UserViewUserInfoCard from "@/views/apps/user/users-view/UserViewUserInfoCard.vue";
import VueApexCharts from "vue-apexcharts";
import userStoreModule from "@/views/apps/user/userStoreModule";
import TestCard from "@/views/apps/user/users-view//TestCard.vue";
import TestSinglePage from "./TestSinglePage.vue";

export default {
  components: {
    VueGoodTable,
    BTooltip,
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BButton,
    BBadge,
    BTable,
    // Local Components
    UserViewUserInfoCard,
    TestCard,
    // UserViewUserPlanCard,
    VueApexCharts,
    TestSinglePage,
    BModal,
    VBModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      dir: false,
      renderPage: {
        page: 1,
        answer: "1_1",
        time: 0,
        right: false,
        point: {
          type_: "",
          value: 0,
          normalized: 0,
        },
      },
      showRender: false,
      showPageKey: 0,
      columns: [
        {
          label: "Sayfa",
          field: "page",
          type: "number",
          tdClass: "text-center",
          thClass: "text-center",
          firstSortType: "asc",
        },
        {
          label: "Cevap",
          type: "string",
          field: "right",
          width: "10%",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Tür",
          type: "string",
          field: "point.type_",
          width: "10%",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Süre",
          field: "time",
          type: "number",
          tdClass: "text-center",
          thClass: "text-center",
          width: "10%",
        },
        {
          label: "Puan",
          type: "number",
          field: "point.normalized",
          tdClass: "text-center",
          thClass: "text-center",
          width: "10%",
        },
        {
          label: "Incele",
          field: "action",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
    };
  },
  methods: {
    goToExercise(row) {
      console.log(row);
      this.renderPage = row;
      // this.showRender = true;
      this.showPageKey++;
    },
    timeButtonVariant(time, right) {
      if (right) {
        if (time < 10) {
          return "success";
        } else if (time < 20) {
          return "warning";
        } else {
          return "danger";
        }
      } else {
        if (time < 10) {
          return "danger";
        } else if (time < 20) {
          return "warning";
        } else if (time >= 30) {
          return "secondary";
        } else {
          return "success";
        }
      }
    },
    pointButtonVariant(point) {
      if (point == 0) {
        return "danger";
      } else {
        return "success";
      }
    },
    memoryTimeParser(times) {
      let returnArr = [];
      for (let i = 0; i < times.length; i++) {
        returnArr.push(`${i + 1} : ${(parseInt(times[i]) / 1000).toFixed(2)}sn`);
      }
      return returnArr.join(" ");
    },
    memoryAnswerSheet(answers, times) {
      let returnArr = [];
      for (let i = 0; i < answers.length; i++) {
        returnArr.push(
          `${i < 9 ? "0" + (i + 1) : i + 1} : ${
            times[i] >= 30000 ? "Boş" : answers[i] ? "Doğru" : "Yanlış"
          }`
        );
      }
      return returnArr.join("  ");
    },
    memoryRightColorCounter(answers, times) {
      const count = answers.reduce((a, b) => a + (b === true), 0);
      const emptyCount = times.reduce((a, b) => a + (b >= 30000), 0);
      if (emptyCount == times.length) return "dark";
      const ratio = count / (answers.length - emptyCount);
      if (ratio == 1) {
        return "success";
      } else if (ratio > 0.75) {
        return "info";
      } else if (ratio > 0.5) {
        return "primary";
      } else if (ratio > 0.25) {
        return "warning";
      } else {
        return "danger";
      }
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    renderPageComputed() {
      return this.renderPage;
    },
  },
  async created() {},
  setup() {
    const userData = ref(null);
    const testData = ref(null);
    const rows = ref([
      {
        page: 1,
        answer: ["1_1"],
        time: 0,
        right: [false],
      },
    ]);
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    store
      .dispatch("app-user/fetchUser", { id: router.currentRoute.params.id })
      .then((response) => {
        userData.value = response.data.subject;
        testData.value = response.data.testData;
        rows.value = response.data.testData.grading;
        console.log(testData.value);
        // console.log(response);
        // console.log(response.data.subject);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      userData,
      testData,
      rows,
    };
  },
};
</script>

<style lang="scss">
/* @import "../stylesheets/testArea.css"; */
@import "@core/scss/vue/libs/vue-good-table.scss";

.time-btn {
  border-radius: 50% !important;
  padding: 10px !important;
}

.report-tooltip {
  position: relative;
  // display: inline-block;
}

.report-tooltip .tooltip-text {
  visibility: hidden;
  line-height: 1.5;
  background-color: $primary;

  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -50px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.report-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
</style>
<style scoped>
body.dark-layout .vgt-wrap .vgt-table th span,
body.dark-layout .vgt-wrap .vgt-table td span,
body.dark-layout .vgt-wrap .vgt-table tr span {
  color: #fff !important;
}

.vgt-wrap {
  overflow: hidden !important;
}
</style>
